html {
  /* ------------------Color Pallet------------------- */
  /* Brand Colors */
  --brand-1: #e5e7ed;
  --brand-2: #e1eaf7;
  --brand-3: #4860bc;
  --brand-4: #324383;
  /* Monochromatic colors */
  --mono-1: #f9f9f9;
  --mono-2: #f0f1f5;
  --mono-3: #c6ccd5;
  --mono-4: #5f6978;
  --mono-5: #132437;

  /* Action Colors */
  --danger: #ff214b;
  --success: #2fd072;
  --warning: #f5a623;
  --white: #fff;
  --black: #000;
  /* Accent colors */
  --accent-1: #f8e71c;
  --accent-2: #7842b9;
  --accent-3: #50e3c2;
}

/* Resets */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* Typography */
html {
  --systemType: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --brandType: "Open Sans", sans-serif;

  /* Font Scale */
  --fs-xxs: 10px;
  --fs-xs: 12px;
  --fs-s: 14px;
  --fs-m: 16px;
  --fs-l: 18px;
  --fs-xl: 20px;
  --fs-xxl: 24px;
  --fs-jumbo: 40px;

  /* Line height */
  --lh-1: 1;
  --lh-2: 1.2;
  --lh-3: 1.3;
  --lh-4: 1.4;
  --lh-5: 1.5;
  --lh-6: 1.6;

  /* Font Weight */
  --fw-light: 300;
  --fw-normal: 400;
  --fw-semi-bold: 600;
  --fw-bold: 700;
}

/* Scaffolding html */
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
  font-family: var(--brandType);
}

body {
  color: var(--mono-5);
  background: var(--mono-1);
  font-size: var(--fs-m);
}

img {
  max-width: 100%;
}

input,
textarea {
  line-height: var(--lh-2);
}

/* Z index scale */
html {
  --z-1: -1;
  --z1: 1;
  --z100: 100;
  --z200: 200;
  --z300: 300;
  --z600: 600;
  --z700: 700;
  --z800: 800;
  --z900: 900;
  --z1000: 1000;
  --inspectorLevel0: 1000;
  --inspectorLevel1: 1100;
  --inspectorLevel2: 1200;
  --confirmationModal: 1300;
}

/* Box shadow scale */
html {
  --box-shadow-0: 0 0 1px 0 rgba(0, 0, 0, 0.2);
  --box-shadow-1: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  --box-shadow-2: 0 0 25px 0 rgba(0, 0, 0, 0.2);
}

/* Spacing */
html {
  --space-xxxs: 2px;
  --space-xxs: 4px;
  --space-xs: 8px;
  --space-s: 12px;
  --space-m: 16px;
  --space-l: 24px;
  --space-xl: 32px;
  --space-xxl: 40px;
  --space-huge: 48px;
}

:focus {
  outline: 1px dashed var(--mono-4);
}
